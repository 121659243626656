import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_REGION, GET_REGIONS, SEARCH_REGION, STORE_REGION, UPDATE_REGION } from "@/store/modules/region.module";

export default {
  computed: {
    ...mapGetters(['regions', 'regionsMeta', 'region', 'regionError', 'searchRegionList', 'searchRegionListMeta'])
  },
  methods: {
    getRegion(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_REGION, id),
      actions
      );
    },
    getRegions(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('regionBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('regionBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_REGIONS, {page, title}),
        actions
      );
    },
    storeRegion(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_REGION, formData),
        actions
      );
    },
    updateRegion(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_REGION, formData),
      actions
      );
    },
    searchRegion(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(SEARCH_REGION, {title, page}),
      actions
      );
    },
  }
}
