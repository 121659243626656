<template>
  <b-overlay :show="regionBusy">
    <portlet title="رايۇن ئۇچۇرى تەھرىرلەش">
      <template v-slot:body>
        <region-form
          ref="region"
          action="edit"
          :model-id="regionId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger" @click="onClickReset">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import RegionForm from "@v@/components/forms/RegionForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, RegionForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'رايۇن باشقۇرۇش', route: {name: 'region.home'}});
      BreadCrumbs.addBreadCrumb({title: 'رايۇن ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('region') ){
        this.form = this.$refs['region'];
      }
    },
    computed: {
      regionId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    data(){
      return {
        regionBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('regionBusy');
        debugConsole({prefix: 'region', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('regionBusy');
        debugConsole({prefix: 'region', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('regionBusy');
        debugConsole({prefix: 'region', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'رايۇن ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'region.home'});
      },
    }
  }
</script>

<style scoped>

</style>
