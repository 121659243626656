<template>
  <div class="region-home position-relative">
    <b-overlay :show="regionBusy">
      <pagination :meta="regionsMeta" place-holder="رايون نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'region.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="region in regions" :key="`region_${region.id}`" md="3" sm="6">
          <portlet :title="region.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="region.is_show" @change="onChangeSwitch( region.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'region.edit', params: {id: region.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(region.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كىنو سانى: </span>
                        <span class="kt-widget__data"> {{ region.films_count }} پارچە </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!regions.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن رايون قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'region.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">رايون قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import regionMixin from "@m@/region.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_REGION_ERROR} from "@/store/modules/region.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [regionMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        regionBusy: false,
        page: 1,
        title: ''
      };
    },
    computed: {},
    created() {
      this.getRegions();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'رايۇن باشقۇرۇش', route: {name: 'region.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق رايۇنلار'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getRegions(page, title);
      },
      onRefresh(title){
        this.getRegions(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'films/regions',
          id, column, mutationType: SET_REGION_ERROR,
          begin: ()=>{ this.showBusy('regionBusy') },
          after: ()=>{ this.hideBusy('regionBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر رايۇننى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_REGION_ERROR, resource: 'films/regions',
                begin: ()=>{ this.showBusy('regionBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getRegions(page, title);
                  this.hideBusy('regionBusy');
                },
              })
            }
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
